<template>
  <div class="container">
    <ErrorMessage v-if="errorMessage" :errorMessage="errorMessage" />
    <div class="form-container">
      <div class="welcome-message">Welcome To Comma</div>
      <div class="form">
        <form
          @submit.prevent="handleLogin"
          class="g-3 needs-validation"
          novalidate
        >
          <div class="col-md-12">
            <label for="email" class="form-label">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="email"
              required
            />
            <div class="valid-feedback">Looks good!</div>
          </div>

          <div class="col-md-12">
            <label for="phone" class="form-label">phone</label>
            <input
              type="phone"
              class="form-control"
              id="phone"
              v-model="phone"
              required
            />
            <div class="valid-feedback">Looks good!</div>
          </div>

          <div class="col-12">
            <button class="btn btn-primary mt-3" type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import axios, { setAuthorizationHeader } from "../../Axios";
import { store } from "../../Store"; // Adjust the path as necessary
import ErrorMessage from "../Errors/ErrorMessage.vue";
export default {
  name: "LoginComponent",
  data() {
    return {
      email: "",
      phone: "",
      errorMessage: "",
    };
  },

  components: {
    ErrorMessage,
  },
  methods: {
    async handleLogin() {
      try {
        const response = await axios.post("admin/auth/login", {
          email: this.email,
          phone: this.phone,
        });
        console.log("Login successful", response.data);
        const token = response.data.data.token;
        localStorage.setItem("access_token", token);
        setAuthorizationHeader(token);
        store.setLoggedIn(true);
        this.$router.push({ name: "home" });
        // Redirect or perform other actions as needed
      } catch (error) {
        console.error("Login failed", error.response.data.message);
        this.errorMessage = error.response.data.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 3500);
      }
    },
  },
};
</script>

<style scoped>
.form-container {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  /* background-color: #f5f5f5;  */
}

.welcome-message {
  font-size: 1.5rem; /* Optional: Adjust font size */
  margin-bottom: 1rem; /* Space between the message and the form */
  text-align: center; /* Center text */
}

.form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 50%;
  max-width: 500px; /* Optional: Max width for better responsiveness */
}

form button {
  width: 100%;
}
</style>
