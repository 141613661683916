<template>
  <div class="sidebar app-aside" id="sidebar">
    <div class="sidebar-container perfect-scrollbar">
      <nav>
        <!-- start: SEARCH FORM -->
        <!-- <div class="search-form">
          <a class="s-open" href="#">
            <i class="ti-search"></i>
          </a>
          <form class="navbar-form" role="search">
            <a class="s-remove" href="#" target=".navbar-form">
              <i class="ti-close"></i>
            </a>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Search..." />
              <button class="btn search-button" type="submit">
                <i class="ti-search"></i>
              </button>
            </div>
          </form>
        </div> -->
        <!-- end: SEARCH FORM -->
        <!-- start: MAIN NAVIGATION MENU -->
        <div class="navbar-title">
          <span>Main Navigation</span>
        </div>
        <ul class="main-navigation-menu">
          <li class="">
            <a href="index.html">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-home"></i>
                </div>
                <div class="item-inner">
                  <span class="title"> Dashboard </span>
                </div>
              </div>
            </a>
          </li>
          <!-- Start Countries -->
          <li>
            <router-link
              :to="{ name: 'all-countries' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Countries</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End Countries -->

          <!-- Start Cities -->
          <li>
            <router-link
              :to="{ name: 'all-cities' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Cities</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End Cities -->

          <!-- Start Districts -->
          <li>
            <router-link
              :to="{ name: 'all-districts' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Districts</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End Districts -->

          <!--Start Services -->
          <li>
            <router-link
              :to="{ name: 'all-services' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-settings"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Services</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End Services -->

          <!--Start SubServices -->
          <li>
            <router-link
              :to="{ name: 'all-subServices' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-settings"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Sub-Services</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End SubServices -->

          <!--Start Specialities -->
          <li>
            <router-link
              :to="{ name: 'all-specialities' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-settings"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Specialities</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End SubServices -->

          <!--Start Doctors -->
          <li>
            <router-link
              :to="{ name: 'all-doctors' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-settings"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Doctors</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End Doctors -->
          
          <!--Start Labs -->
          <li>
            <router-link
              :to="{ name: 'all-labs' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-settings"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Labs</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End Labs -->

                <!--Start Condition Medical -->
                <li>
            <router-link
              :to="{ name: 'all-conditionMedicals' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-settings"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Condition Medical</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End Condition Medical -->

          <!--Start InfoServices -->
          <li>
            <router-link
              :to="{ name: 'all-infoServices' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-settings"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Information Services</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End InfoServices -->

          <!--Start Branches -->
          <li>
            <router-link
              :to="{ name: 'all-branches' }"
              class="list-group-item list-group-item-action py-2 ripple"
            >
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-settings"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Branches</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End Branches -->
        </ul>
        <!-- end: MAIN NAVIGATION MENU -->
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
};
</script>
