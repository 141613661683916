<template>
  <div>
    <div class="container">
      <!-- head -->
      <div class="row">
        <div class="col-md-4">
          <input
            type="text"
            class="form-control"
            id="search"
            placeholder="Search"
            v-model="searchTerm"
          />
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <router-link class="btn btn-primary" :to="{ name: 'all-branches' }"
            >All Branches</router-link
          >
        </div>
      </div>

      <!-- table og content -->
      <div class="row">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(branche, index) in filteredBranches" :key="index">
              <th scope="row">{{ branche.id }}</th>
              <td>{{ branche.name }}</td>
              <td>
                <button
                  class="btn btn-success"
                  @click="restoreBranche(branche.id)"
                >
                  Restore
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- pagination -->
      <div class="row">
        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <paginate
                :page-count="meta.last_page"
                :click-handler="fetchBranches"
                :prev-text="'Previous'"
                :next-text="'Next'"
                :initial-page="meta.current_page"
              ></paginate>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Paginate from "vuejs-paginate-next";
// import axios from 'axios';
import Paginate from "vuejs-paginate-next";
import axios from "../../Axios";
export default {
  name: "DeletedBranches",
  components: {
    Paginate,
  },
  data() {
    return {
      branches: [],
      meta: {},
      searchTerm: "",
    };
  },

  async created() {
    this.fetchBranches();
  },

  computed: {
    filteredBranches() {
      if (!this.searchTerm) {
        return this.branches;
      }
      const term = this.searchTerm.toLowerCase();
      return this.branches.filter((branche) =>
        branche.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch branches
    async fetchBranches(page = 1) {
      try {
        const response = await axios.get(
          `/admin/branch/get-delete-soft?page=${page}&is_paginate=15`
        );
        this.branches = response.data.data;
        this.meta = response.data.meta;

        console.log(this.branches);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    },

    restoreBranche(id) {
      console.log("restore branche id: ", id);
      axios
        .post(`/admin/branch/restore/${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchBranches();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>
