<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create City</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-cities' }"
              >Back</router-link
            >
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createCity">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter English Name"
                      v-model="name_en"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="cityName">Arabic Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Arabic Name"
                      v-model="name_ar"
                    />
                  </div>
                </div>
                <div class="form-group">
              <label for="countrySelect">Select Country</label>
              <select
                class="form-select"
                v-model="country_id"
                aria-label="Select country"
              >
                <option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>
                <!-- <div class="col-md-12">
                  <div class="form-group">
                    <label for="form-field-select-2"> Select Country </label>
                    <select class="cs-select cs-skin-elastic">
                      <option value="" disabled selected>
                        Select a Country
                      </option>
                      <option value="1">Egypt</option>
                    </select>
                  </div>
                </div> -->
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
export default {
  name: "CreateCity",
  data() {
    return {
      name_en: "",
      name_ar: "",
      //      postal_code: "",
      country_id: "",
    };
  },

  methods: {
    async fetchCountries() {
      try {
        const response = await axios.get("/admin/country/getAll");
        this.countries = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async createCity() {
      try {
        const response = await axios.post("admin/admin/city/create", {
          name_en: this.name_en,
          name_ar: this.name_ar,
          country_id: this.country_id,
        });
        console.log(response.data);
        this.$router.push({ name: "all-cities" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
