<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Districts</h1>
         
            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'create-district' }"
            >create</router-link
          >
          </div>  
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <h5 class="over-title margin-bottom-15">
              Basic <span class="text-bold">Data Table</span>
            </h5>
            <p>
              DataTables is a plug-in for the jQuery Javascript library. It is a
              highly flexible tool, based upon the foundations of progressive
              enhancement, and will add advanced interaction controls to any
              HTML table.
            </p>
            <table
              class="table table-striped table-bordered table-hover table-full-width"
              id="sample_1"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name Arabic</th>
                  <th>Name English</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(district, index) in filteredDistricts" :key="index">
                  <th scope="row">{{ district.id }}</th>
                  <td>{{ district.name_ar }}</td>
                  <td>{{ district.name_en }}</td>
                  <td>
                    <button
                      class="me-2 btn btn-danger"
                      @click="deleteDistrict(district.id)"
                    >
                      Delete
                    </button>
                    <router-link
                      class="btn btn-primary spacing"
                      :to="{ name: 'edit-district', params: { id: district.id } }"
                    >
                      Edit
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
// import Paginate from "vuejs-paginate-next";
// import axios from 'axios';
// import Paginate from "vuejs-paginate-next";
import axios from "../../Axios";
export default {
  name: "AllDistricts",
  
  components: {
    // Paginate,
  },
  data() {
    return {
      districts: [],
      meta: {},
      searchTerm: "",
    };
  },

  async created() {
    this.fetchDistricts();
    // this.$nextTick(() => {
    //   this.initializeDataTable();
    // });
  },

  computed: {
    filteredDistricts() {
      if (!this.searchTerm) {
        return this.districts;
      }
      const term = this.searchTerm.toLowerCase();
      return this.districts.filter(
        (district) =>
          district.name.toLowerCase().includes(term) ||
          district.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch districts
    async fetchDistricts() {
      try {
        const response = await axios.get(
          `/admin/admin/district/all`
        );
        this.districts = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },

    deleteDistrict(id) {
      console.log("delete district id: ", id);
      axios
        .delete(`/admin/district/delete/${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchDistricts();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  async initializeDataTable() {
      // const $ = this.$;
      // const table = $("#sample_1").DataTable({
      //   paging: true,
      //   searching: true,
      //   ordering: true
      // });

    
    },


  //   async mounted() {   
  //   this.initializeDataTable();
  // },
};

// var table = $("#sample_1").DataTable();
// $("#addRow").on("click", function () {
//   table.row
//     .add([
//       "3", // First Column (Index)
//       "Test Record", // Second Column (Name)
//       "32,6154 USD", // Third Column (Amount)
//       "21", // Fourth Column (Age)
//     ])
//     .draw(false); // 'draw(false)' prevents table from being re-sorted
// });
</script>

