import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "./Axios.js";
import $ from "jquery"; // Use this import statement for jQuery
import 'datatables.net';

// import 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Qn35GGmFwlcIHeAdTL2_gpweBdNo5YI&libraries=places';

// import '../public/vendor/jquery.cookie';
// import '../public/vendor/perfect-scrollbar';
// import '../public/vendor/switchery';
// import '../public/vendor/chart.js';
// import '../public/vendor/jquery.sparkline';
// import '../public/vendor/maskedinput';
// import '../public/vendor/bootstrap-touchspin';
// import '../public/vendor/autosize';
// import '../public/vendor/select2';
// import '../public/vendor/bootstrap-datepicker';
// import '../public/vendor/bootstrap-timepicker';
// import '../public/vendor/bootstrap-fileinput';
// import '../public/vendor/selectFx'

// document.addEventListener('DOMContentLoaded', () => {
//     $('#sample_1').DataTable(); // Example for initializing DataTables
    
//   });

//   $(document).ready(function () {
//     Main.init();
//     Index.init();
//     TableData.init();
//   });

// import the package
// import VueAwesomePaginate from "vue-awesome-paginate";

// // import the necessary css file
// import "vue-awesome-paginate/dist/style.css";
//
// import Paginate from "vuejs-paginate-next";

const app = createApp(App);
// app.appUrl = "https://localhost:8080";
app.config.globalProperties.$ = $;
app.config.globalProperties.jQuery = $; // Optional: For compatibility  

createApp(App).use(router).mount("#app");

