<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Edit Branche</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <form class="text-start mx-auto" @submit.prevent="editBranche">
            <div class="form-group">
              <label class="text-">English Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter English Name"
                v-model="name_en"
              />
            </div>

            <div class="form-group">
              <label for="cityName">Arabic Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Arabic Name"
                v-model="name_ar"
              />
            </div>

            <div class="form-group">
              <label for="brancheAddress">Address</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Address"
                v-model="address"
              />
            </div>

            <!-- GoogleMap component with lat and long props -->
            <!-- <google-map :lat="lat" :long="long" /> -->

            <GoogleMap :coords="{ lat: lat, lng: long }" @placeChanged="handlePlaceChanged" />
            <div class="form-group">
              <label>Latitude</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Latitude"
                v-model="lat"
              />
            </div>

            <div class="form-group">
              <label>Longitude</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Longitude"
                v-model="long"
              />
            </div>
  

            <button class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import GoogleMap from "../Component/GoogleMap.vue";

export default {
  name: "EditBranche",
  components: {
    GoogleMap,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      address: "",
      lat:"", // Lat and long for input fields
      long: "", // long and long for input fields
    };
  },
  created() {
    this.fetchBranche();
  },
  methods: {
    handlePlaceChanged(place) {
      // Update lat and long based on the selected place
      // this.lat = place.lat;
      // this.long = place.lng;
      place.lat= this.lat ;
      place.lng=this.long  ;
    },
    async fetchBranche() {
      try {
        const response = await axios.get(
          "/admin/branch/get/" + this.$route.params.id
        );
        // console.log(response.data.data);
        this.name_en = response.data.data.name_en;
        this.name_ar = response.data.data.name_ar;
        this.address = response.data.data.address;
        this.lat = response.data.data.lat;
        this.long = response.data.data.long;
        

      } catch (error) {
        console.error(error);
      }
    },
    async editBranche() {
      try {
        const formData = new FormData();
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("address", this.address);
        formData.append("lat", this.lat);
        formData.append("long", this.long)

        // Append selected images
        this.selectedFiles.forEach((file, index) => {
          formData.append(`images[${index}]`, file);
        });

        const response = await axios.post(
          "/admin/branch/update/" + this.$route.params.id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-branches" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 70%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
