<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Speciality</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-specialities' }"
              >Back</router-link
            >
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createSpeciality" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter English Name"
                      v-model="name_en"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="specialityName">Arabic Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Arabic Name"
                      v-model="name_ar"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="specialityName">English Description </label>
                    <textarea
                      rows="3"
                      class="form-control"
                      placeholder="Enter English Description"
                      v-model="description_en"
                    ></textarea>
                  </div>
                </div>
                
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="specialityName">Arabic Description </label>
                    <textarea
                      rows="3"
                      class="form-control"
                      placeholder="Enter Arabic Description"
                      v-model="description_ar"
                    ></textarea>
                  </div>
                </div>
        
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
export default {
  name: "CreateSpeciality",
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
    };
  },

  methods: {
    async createSpeciality() {
      try {
        console.log(this.image);
           // Prepare form data for upload
      const formData = new FormData();
      formData.append('name_en', this.name_en);
      formData.append('name_ar', this.name_ar);
      formData.append('description_en', this.description_en);
      formData.append('description_ar', this.description_ar);
      const response = await axios.post("admin/admin/speciality/create", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
        console.log(response.data);
        this.$router.push({ name: "all-specialities" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
