<template>
    <div>
    <div :class="{ 'main-content': !isLoginPage }" style="padding: 0 !important; margin: 30px !important;">
      <NavBar v-if="store.isLoggedIn" />
      <SideBar v-if="store.isLoggedIn" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { store } from "./Store"; // Adjust the path as necessary
import NavBar from "./components/Navigation/NavBar.vue";
import SideBar from "./components/Navigation/SideBar.vue";
import { useRoute } from "vue-router";
import { onMounted, ref, watch } from "vue";




export default {
  name: "App",
  components: {
    NavBar,
    SideBar,
  },
  setup() {
    const route = useRoute();
    const isLoginPage = ref(route.meta.noNav || false);

    // Watch the route for changes
    watch(route, (newRoute) => {
      isLoginPage.value = newRoute.meta.noNav || false;
    });

    onMounted(() => {
      if (localStorage.getItem("access_token")) {
        store.setLoggedIn(true);
      }
    });

    return { store, isLoginPage };
  },
  created() {
    if (localStorage.getItem("access_token")) {
      store.setLoggedIn(true);
    }
  },
};
</script>


