<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Speciality</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-specialities' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editSpeciality">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter English Name"
                      v-model="name_en"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="specialityName">Arabic Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Arabic Name"
                      v-model="name_ar"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="specialityName">English Description</label>
                    <textarea
                      rows="3"
                      class="form-control"
                      placeholder="Enter English Description"
                      v-model="description_en"
                    ></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="specialityName">Arabic Description</label>
                    <textarea
                      rows="3"
                      class="form-control"
                      placeholder="Enter Arabic Description"
                      v-model="description_ar"
                    ></textarea>
                  </div>
                </div>

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "EditSpeciality",
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      image: null,
      existingImage: "", // URL of the existing image
      removeImageFlag: false, // Flag to indicate if the image should be removed
    };
  },
  computed: {
    imagePreviewUrl() {
      return this.image ? URL.createObjectURL(this.image) : this.existingImage;
    },
  },
  created() {
    this.fetchSpeciality();
  },
  methods: {
    async fetchSpeciality() {
      try {
        const response = await axios.get(
          "/admin/admin/speciality/get?id=" + this.$route.params.id
        );
        // const response = await axios.get(`admin/admin/speciality/get/${this.$route.params.id}`);
        this.name_en = response.data.data.name_en;
        this.name_ar = response.data.data.name_ar;
        this.description_en = response.data.data.description_en;
        this.description_ar = response.data.data.description_ar;
        this.name_ar = response.data.data.name_ar;

        this.existingImage = response.data.data.image; // Assuming the response includes an image URL

        // Set up preview for existing image
        if (this.existingImage) {
          this.$nextTick(() => {
            const imgElement = this.$refs.imagePreview;
            imgElement.src = this.existingImage;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.imagePreview.src = e.target.result; // Preview the selected image
        };
        reader.readAsDataURL(file);
      }
    },
    async removeImage() {
      console.log('this.$refs.imagePreview.src',this.$refs.imagePreview.src);

      // console.log('this.image',this.image);
      // console.log('this.removeImageFlag',this.removeImageFlag);
      // const url = new URL(this.existingImage);
         // Send delete request to server
         await axios.post(`admin/admin/speciality/delete-image`, {
         image: this.$refs.imagePreview.src ,id:this.$route.params.id, // Send filename in request body
      });
      this.image = null;
      this.removeImageFlag = true;
      this.$refs.imagePreview.src = ""; // Clear the preview image
      // const imageName = url.pathname.split('/').pop(); // Extract filename with extension

      
    },
    async editSpeciality() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("description_en", this.description_en);
        formData.append("description_ar", this.description_ar);
        if (this.image) {
          formData.append("image", this.image); // Append the selected file
        }
        if (this.removeImageFlag) {
          formData.append("remove_image", true); // Flag to indicate removal
        }
        const response = await axios.post(
          "admin/admin/speciality/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-specialities" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 100%;
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
