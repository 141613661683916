<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All SubServices</h1>
         
            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'create-subService' }"
            >create</router-link
          >
          </div>  
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <h5 class="over-title margin-bottom-15">
              Basic <span class="text-bold">Data Table</span>
            </h5>
            <p>
              DataTables is a plug-in for the jQuery Javascript library. It is a
              highly flexible tool, based upon the foundations of progressive
              enhancement, and will add advanced interaction controls to any
              HTML table.
            </p>
            <table
              class="table table-striped table-bordered table-hover table-full-width"
              id="sample_1"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name Arabic</th>
                  <th>Name English</th>
                  <th>Main Service</th>
                  <th>Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(subService, index) in filteredSubServices" :key="index">
                  <th scope="row">{{ subService.id }}</th>
                  <td>{{ subService.name_ar }}</td>
                  <td>{{ subService.name_en }}</td>
                  <td>{{ subService.service.name }}</td>
                  <td>
                    <img
                      :src="`${subService.image}`"
                      width="50"
                      height="50"
                      class="img-thumbnail"
                    />
                  </td>
                  <!-- <td><img :src="`${assets}subService.image`"></td> -->
                  
                  <td>
                    <button
                      class="me-2 btn btn-danger"
                      @click="deleteSubService(subService.id)"
                    >
                      Delete
                    </button>
                    <router-link
                      class="btn btn-primary spacing"
                      :to="{ name: 'edit-subService', params: { id: subService.id } }"
                    >
                      Edit
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
// import Paginate from "vuejs-paginate-next";
// import axios from 'axios';
// import Paginate from "vuejs-paginate-next";
import axios from "../../Axios";
export default {
  name: "AllSubServices",
  components: {
    // Paginate,
  },
  data() {
    return {
      subServices: [],
      meta: {},
      searchTerm: "",
    };
  },

  async created() {
    this.fetchSubServices();
    // this.$nextTick(() => {
    //   this.initializeDataTable();
    // });
  },

  computed: {
    filteredSubServices() {
      if (!this.searchTerm) {
        return this.subServices;
      }
      const term = this.searchTerm.toLowerCase();
      return this.subServices.filter(
        (subService) =>
          subService.name.toLowerCase().includes(term) ||
          subService.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch subServices
    async fetchSubServices() {
      try {
        const response = await axios.get(
          `/admin/admin/sub-service/all`
        );
        this.subServices = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching subServices:", error);
      }
    },

    deleteSubService(id) {
      console.log("delete subService id: ", id);
      axios
        .delete(`/admin/admin/sub-service/delete/${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchSubServices();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};


</script>

