import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import AboutView from "../views/AboutView.vue";
import LoginView from "../views/Auth/LoginView.vue";

//Start Countries routes
import AllCountries from "../components/Countries/AllCountries.vue";
import CreateCountry from "../components/Countries/CreateCountry.vue";
import EditCountry from "../components/Countries/EditCountry.vue";
//End Countries routes

//Start Cities routes
import AllCities from "../components/Cities/AllCities.vue";
import CreateCity from "../components/Cities/CreateCity.vue";
import EditCity from "../components/Cities/EditCity.vue";
//Start Cities routes

//Start Districts routes
import AllDistricts from "../components/Districts/AllDistricts.vue";
import CreateDistrict from "../components/Districts/CreateDistrict.vue";
import EditDistrict from "../components/Districts/EditDistrict.vue";
//Start Cities routes

//Start Service routes
import AllServices from "../components/Services/AllServices.vue";
import CreateService from "../components/Services/CreateService.vue";
import EditService from "../components/Services/EditServices.vue";
//End Service routes

//Start SubService routes
import AllSubServices from "../components/SubServices/AllSubServices.vue";
import CreateSubService from "../components/SubServices/CreateSubService.vue";
import EditSubService from "../components/SubServices/EditSubServices.vue";
//End SubService routes

//Start Doctors routs
import AllDoctors from "../components/Doctors/AllDoctors.vue";
import CreateDoctor from "../components/Doctors/CreateDoctor.vue";
import EditDoctor from "../components/Doctors/EditDoctor.vue";

//Start Specialty routes
import AllSpecialities from "../components/Specialities/AllSpecialities.vue";
import CreateSpeciality from "../components/Specialities/CreateSpeciality.vue";
import EditSpeciality from "../components/Specialities/EditSpeciality.vue";
//End Service routes

//Start InfoService routes
import AllInfoServices from "../components/InfoServices/AllInfoServices.vue";
import CreateInfoService from "../components/InfoServices/CreateInfoService.vue";
import EditInfoService from "../components/InfoServices/EditInfoService.vue";
//End InfoService routes


//Start ConditonMedical routes
import AllConditionMedicals from "../components/ConditionMedicals/AllConditionMedicals.vue";
import CreateConditionMedical from "../components/ConditionMedicals/CreateConditionMedical.vue";
import EditConditionMedical from "../components/ConditionMedicals/EditConditionMedical.vue";
//End ConditonMedical routes

//End Labs routes
import AllLabs from "../components/Labs/AllLabs.vue";
import CreateLab from "../components/Labs/CreateLab.vue";
//End Labs routes


import AllBranches from "../components/Branches/AllBranches.vue";
import CreateBranche from "../components/Branches/CreateBranche.vue";
import EditBranche from "../components/Branches/EditBranche.vue";
import DeletedBranches from "../components/Branches/DeletedBranches.vue";



//Users routes
// import AllUsers from "../components/Users/AllUsers.vue";
// import CreateUser from "../components/Users/CreateUser.vue";
// import EditUser from "../components/Users/EditUser.vue";
// import DeletedUsers from "../components/Users/DeletedUsers.vue";




//contact Informations routes
// import AllContactInformations from "../components/ContactInformations/AllContactInformations.vue";
// import AllCountries from "@/components/Countries/AllCountries.vue";




const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
    meta: { noNav: true }, // Add a meta field to identify the login route
  },
  // {
  //   path: "/",
  //   name: "login",
  //   component: LoginView,
  // },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },

  //country routes
  {
    path: "/country/all",
    name: "all-countries",
    component: AllCountries,
    meta: { requiresAuth: true },
  },
  {
    path: "/country/create",
    name: "create-country",
    component: CreateCountry,
    meta: { requiresAuth: true },
  },
  {
    path: "/country/edit/:id",
    name: "edit-country",
    component: EditCountry,
    meta: { requiresAuth: true },
  },

  //Start service routes
  {
    path: "/service/all",
    name: "all-services",
    component: AllServices,
    meta: { requiresAuth: true },
  },
  {
    path: "/service/create",
    name: "create-service",
    component: CreateService,
    meta: { requiresAuth: true },
  },
  {
    path: "/service/edit/:id",
    name: "edit-service",
    component: EditService,
    meta: { requiresAuth: true },
  },
  //end service routes

  //Start subService routes
  {
    path: "/subService/all",
    name: "all-subServices",
    component: AllSubServices,
    meta: { requiresAuth: true },
  },
  {
    path: "/subService/create",
    name: "create-subService",
    component: CreateSubService,
    meta: { requiresAuth: true },
  },
  {
    path: "/subService/edit/:id",
    name: "edit-subService",
    component: EditSubService,
    meta: { requiresAuth: true },
  },
  //end subService routes

  //Start speciality routes
  {
    path: "/speciality/all",
    name: "all-specialities",
    component: AllSpecialities,
    meta: { requiresAuth: true },
  },
  {
    path: "/speciality/create",
    name: "create-speciality",
    component: CreateSpeciality,
    meta: { requiresAuth: true },
  },
  {
    path: "/speciality/edit/:id",
    name: "edit-speciality",
    component: EditSpeciality,
    meta: { requiresAuth: true },
  },
  //end speciality routes

  //Start InfoService routes
  {
    path: "/infoService/all",
    name: "all-infoServices",
    component: AllInfoServices,
    meta: { requiresAuth: true },
  },
  {
    path: "/infoService/create",
    name: "create-infoService",
    component: CreateInfoService,
    meta: { requiresAuth: true },
  },
  {
    path: "/infoService/edit/:id",
    name: "edit-infoService",
    component: EditInfoService,
    meta: { requiresAuth: true },
  },
  //End InfoService

  //Start ConditionMedical routes
  {
    path: "/conditionMedical/all",
    name: "all-conditionMedicals",
    component: AllConditionMedicals,
    meta: { requiresAuth: true },
  },
  {
    path: "/conditionMedical/create",
    name: "create-conditionMedical",
    component: CreateConditionMedical,
    meta: { requiresAuth: true },
  },
  {
    path: "/conditionMedical/edit/:id",
    name: "edit-conditionMedical",
    component: EditConditionMedical,
    meta: { requiresAuth: true },
  },
  //End ConditionMedical

  //start Labs routes
  {
    path: "/lab/all",
    name: "all-labs",
    component: AllLabs,
    meta: { requiresAuth: true },
  },
  {
    path: "/lab/create",
    name: "create-lab",
    component: CreateLab,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/lab/edit/:id",
  //   name: "edit-lab",
  //   component: EditLabs,
  //   meta: { requiresAuth: true },
  // },
  //End Labs routes

  // {
  //   path: "/about",
  //   name: "about",
  //   component: AboutView,
  //   meta: { requiresAuth: true },
  // },

  //city routes
  {
    path: "/city/all",
    name: "all-cities",
    component: AllCities,
    meta: { requiresAuth: true },
  },
  {
    path: "/city/create",
    name: "create-city",
    component: CreateCity,
    meta: { requiresAuth: true },
  },
  {
    path: "/city/edit/:id",
    name: "edit-city",
    component: EditCity,
    meta: { requiresAuth: true },
  },

  //district routes
  {
    path: "/district/all",
    name: "all-districts",
    component: AllDistricts,
    meta: { requiresAuth: true },
  },
  {
    path: "/district/create",
    name: "create-district",
    component: CreateDistrict,
    meta: { requiresAuth: true },
  },
  {
    path: "/district/edit/:id",
    name: "edit-district",
    component: EditDistrict,
    meta: { requiresAuth: true },
  },

  //Start Doctors routes
  {
    path: "/doctors/all",
    name: "all-doctors",
    component: AllDoctors,
    meta: { requiresAuth: true },
  },
  {
    path: "/doctor/create",
    name: "create-doctor",
    component: CreateDoctor,
    meta: { requiresAuth: true },
  },
  {
    path: "/doctor/edit/:id",
    name: "edit-doctor",
    component: EditDoctor,
    meta: { requiresAuth: true },
  },
  //End Doctors routes


  {
    //branches routes
    path: "/branches/all",
    name: "all-branches",
    component: AllBranches,
    meta: { requiresAuth: true },
  },
  {
    path: "/branche/branche",
    name: "create-branche",
    component: CreateBranche,
    meta: { requiresAuth: true },
  },
  {
    path: "/branche/edit/:id",
    name: "edit-branche",
    component: EditBranche,
    meta: { requiresAuth: true },
  },
  {
    path: "/branche/all/deleted",
    name: "deleted-branches",
    component: DeletedBranches,
    meta: { requiresAuth: true },
  },


  //Users routes
  // {
  //   path: "/users/all",
  //   name: "all-users",
  //   component: AllUsers,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/user/create",
  //   name: "create-user",
  //   component: CreateUser,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/user/edit/:id",
  //   name: "edit-user",
  //   component: EditUser,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/user/all/deleted",
  //   name: "deleted-users",
  //   component: DeletedUsers,
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/ContactInformations/all",
  //   name: "all-contacts",
  //   component: AllContactInformations,
  //   meta: { requiresAuth: true },
  // },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("access_token"); // Check if user is logged in

  if (to.name === "login" && loggedIn) {
    // If the user is logged in and trying to access the login route
    next({ name: "home" }); // Redirect to the home page
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !loggedIn
  ) {
    // If the route requires authentication and user is not logged in
    next({ name: "login" }); // Redirect to login
  } else {
    next(); // Allow navigation
  }
});



export default router;
