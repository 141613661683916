<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Service</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-services' }"
              >Back</router-link
            >
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form
              class="text-start mx-auto"
              @submit.prevent="createService"
              enctype="multipart/form-data"
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter English Name"
                      v-model="name_en"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="serviceName">Arabic Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Arabic Name"
                      v-model="name_ar"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="serviceName">English Description </label>
                    <textarea
                      rows="3"
                      class="form-control"
                      placeholder="Enter English Description"
                      v-model="description_en"
                    ></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="serviceName">Arabic Description </label>
                    <textarea
                      rows="3"
                      class="form-control"
                      placeholder="Enter Arabic Description"
                      v-model="description_ar"
                    ></textarea>
                  </div>
                </div>

                <div class="form-group">
                  <label> Image Upload </label>
                  <div
                    class="fileinput fileinput-new"
                    data-provides="fileinput"
                  >
                    <div class="fileinput-new thumbnail">
                      <img src="assets/images/avatar-1-xl.jpg" alt="" />
                    </div>
                    <div
                      class="fileinput-preview fileinput-exists thumbnail"
                    ></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"
                        ><span class="fileinput-new"
                          ><i class="fa fa-picture"></i> Select image</span
                        ><span class="fileinput-exists"
                          ><i class="fa fa-picture"></i> Change</span
                        >
                        <input
                          type="file"
                          name="image"
                          @change="handleFileChange"
                        />
                      </span>
                      <a
                        href="#"
                        class="btn fileinput-exists btn-red"
                        data-dismiss="fileinput"
                      >
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Select Service</label>
                  <select
                    class="form-control"
                    v-model="service_id"
                    @change="getServiceId"
                  >
                    <option value="">Select Service</option>
                    <option
                      v-for="service in services"
                      :key="service.id"
                      :value="service.name"
                    >
                      {{ service.name_en }}
                    </option>
                  </select>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
export default {
  name: "CreateService",
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      image: null,
      services: [],
    };
  },
  created() {
    this.fetchService();
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file; // Update with the selected file
      }
    },
    // async fetchCountries() {
    //   try {
    //     const response = await axios.get("/admin/country/getAll");
    //     this.countries = response.data.data;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async fetchService() {
      try {
        const response = await axios.get(
          "/admin/admin/service/all"
        );
        console.log('service',response.data.data );
        
        this.services = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    
    async createService() {
      try {
        console.log(this.image);
        // Prepare form data for upload
        const formData = new FormData();
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("description_en", this.description_en);
        formData.append("description_ar", this.description_ar);
        if (this.image) {
          formData.append("image", this.image); // Append the selected file
        }

        const response = await axios.post(
          "admin/admin/service/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-services" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>

