<template>
  <div>
    <LoginComponent />
  </div>
</template>

<script>
import LoginComponent from "../../components/Auth/LoginComponent.vue";
export default {
  name: "LoginView",
  components: {
    LoginComponent,
  },
};
</script>
