<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Doctor</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-doctors' }"
              >Back</router-link
            >
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createDoctor">
              <div class="row mb-2">
                <div class="form-group col-md-4">
                  <label class="text-"> Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Name"
                    v-model="name"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label class="text-">English Title </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter English Title "
                    v-model="title_en"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label class="text-">Arabic Title</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Arabic Title"
                    v-model="title_ar"
                  />
                </div>

                <div class="form-group col-md-3">
                  <label class="text-">price</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter price"
                    v-model="price"
                  />
                </div>

                <div class="form-group col-md-3">
                  <label class="text-">Price After Discount</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Price After Discount"
                    v-model="price_after_discount"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label class="text-">Experience</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Experience"
                    v-model="experience"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label class="text-">English Description</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="description_en"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <label class="text-">Arabic Description</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="description_ar"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <h5 class="image-label">Images</h5>
                  <input
                    id="images"
                    type="file"
                    class="form-control-file"
                    @change="handleFileUpload"
                    multiple
                    accept="image/*"
                  />

                  <transition-group
                    name="image-list"
                    class="image-previews mt-2"
                  >
                    <img
                      v-for="(image, index) in imagePreviews"
                      :key="image"
                      :src="image"
                      class="img-thumbnail"
                      :alt="'Image ' + (index + 1)"
                      @click="removeImage(index)"
                    />
                  </transition-group>
                </div>

                <div class="form-group col-md-12">
                  <label for="main_image" class="form-label"> main Image</label>
                  <input
                    id="images"
                    type="file"
                    class="form-control-file"
                    @change="handleFileUploadMainImage"
                    multiple
                    accept="image/*"
                  />

                  <transition-group
                    name="image-list"
                    class="image-previews mt-2"
                  >
                    <img
                      v-for="(main_image, index) in main_imagePreviews"
                      :key="main_image"
                      :src="main_image"
                      class="img-thumbnail"
                      :alt="'main_image ' + (index + 1)"
                      @click="removeMainImage(index)"
                    />
                  </transition-group>
                </div>

                <div class="form-group">
                  <button
                    class="btn btn-success add-variant mb-3"
                    @click="addVariant"
                    type="button"
                  >
                    Add Variant
                  </button>
                  <div class="row">
                    <div
                      class="col-md-6"
                      v-for="(variant, index) in variants"
                      :key="index"
                    >
                      <div class="card mb-3">
                        <div class="card-body">
                          <h5 class="card-title">Variant {{ index + 1 }}</h5>

                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">service</label>
                            <multiselect
                              v-model="variant.service_id"
                              :options="services"
                              :searchable="true"
                              label="name"
                              track-by="id"
                              placeholder="Select a service"
                              :multiple="false"
                              @select="
                                handleServiceChange(variant.service_id.id)
                              "
                            ></multiselect>
                          </div>

                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Sub service</label>
                            <multiselect
                              v-model="variant.subService_id"
                              :options="subServices"
                              :searchable="true"
                              label="name"
                              track-by="name"
                              placeholder="Select a Sub service"
                              :multiple="false"
                            ></multiselect>
                          </div>

                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Specialty</label>
                            <multiselect
                              v-model="variant.specialty_id"
                              :options="specialities"
                              :searchable="true"
                              label="name"
                              track-by="name"
                              placeholder="Select a Specialty"
                              :multiple="false"
                            ></multiselect>
                          </div>

                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Infomation Service</label>
                            <multiselect
                              v-model="variant.info_service_id"
                              :options="infoServices"
                              :searchable="true"
                              label="name"
                              track-by="id"
                              placeholder="Select a Infomation Service"
                              :multiple="false"
                            ></multiselect>
                          </div>

                          <button
                            class="btn btn-danger mt-3"
                            @click="removeVariant(index)"
                          >
                            Remove Variant
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  name: "CreateDoctor",
  components: {
    Multiselect,
  },
  data() {
    return {
      name: "",
      title_en: "",
      title_ar: "",
      price: "",
      price_after_discount: "",
      experience: "",
      description_en: "",
      description_ar: "",
      images: [],
      main_image: [],
      imagePreviews: [], // Array to store image previews// Array to store selected files
      selectedImages: [],
      main_selectedImages: [],
      main_imagePreviews: [], // Array to store image previews// Array to store selected files
      services: [],
      subServices: [],
      infoServices: [],
      specialities: [],
      variants: [],
      brands: [],
      categories: [],
      collections: [],
      branches: [],
    };
  },

  async created() {
    this.fetchServices(); // Corrected method name

    // this.fetchInfoServices(); // Corrected method name
    this.fetchSpecialities(); // Corrected method name
  },

  methods: {
    async createDoctor() {
      try {
        const processedVariants = this.getVariants();

        const formData = new FormData();
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("price", Number(this.price)); // Ensure price is a number
        formData.append(
          "price_after_discount",
          Number(this.price_after_discount)
        ); // Ensure price_after_discount is a number
        formData.append("description_ar", this.description_ar);
        formData.append("description_en", this.description_en);
        formData.append("experience", this.experience);
        formData.append("title_ar", this.title_ar);
        formData.append("title_en", this.title_en);
        if (this.main_image) {
          formData.append("main_image", this.main_image);
        }

        processedVariants.forEach((variant, index) => {
          formData.append(
            `variants[${index}][specialty_id]`,
            variant.speciality_id
          );
          formData.append(
            `variants[${index}][info_service_id]`,
            variant.info_service_id
          );

          if (variant.subService_id) {
            formData.append(
              `variants[${index}][subService_id]`,
              variant.subService_id
            );
          } else {
            formData.append(
              `variants[${index}][service_id]`,
              variant.service_id
            );
          }
        });

        // Send the form data using Axios
        const response = await axios.post(
          "/admin/admin/doctor/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-doctors" });
      } catch (error) {
        console.error(error);
      }
    },
    onFileChange(event) {
      this.main_image = event.target.files[0];
    },

    addVariant() {
      this.variants.push({
        service_id: [], // Ensure this is initialized as an array
        speciality_id: [], // Ensure this is initialized as an array
        info_service_id: [], // Ensure this is initialized as an array
        subService_id: [], // Ensure this is initialized as an array
      });
      // this.logVariants();
    },
    removeVariant(index) {
      this.variants.splice(index, 1);
    },
    logVariants() {
      // console.log(this.variants);
    },
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.selectedImages.push(...files);
      this.imagePreviews.push(
        ...files.map((file) => URL.createObjectURL(file))
      );
      event.target.value = null; // Reset the file input
    },

    handleFileUploadMainImage(event) {
      const files = Array.from(event.target.files);
      this.main_selectedImages.push(...files);
      this.main_imagePreviews.push(
        ...files.map((file) => URL.createObjectURL(file))
      );
      event.target.value = null; // Reset the file input
    },
    removeImage(index) {
      // Remove the image from selectedImages and imagePreviews
      this.selectedImages.splice(index, 1);
      URL.revokeObjectURL(this.imagePreviews[index]); // Clean up object URL
      this.imagePreviews.splice(index, 1); // Remove the preview URL from the array
    },
    removeMainImage(index) {
      // Remove the image from selectedImages and imagePreviews
      this.main_selectedImages.splice(index, 1);
      URL.revokeObjectURL(this.main_imagePreviews[index]); // Clean up object URL
      this.main_imagePreviews.splice(index, 1); // Remove the preview URL from the array
    },
    getVariants() {
      return this.variants.map((variant) => {
        return {
          // additional_price: variant.additional_price,
          speciality_id: variant.speciality_id.id,
          // size_id: Array.isArray(variant.size_id)
          //   ? variant.size_id.map((size) => size.id)
          //   : [],
          info_service_id: variant.info_service_id.id,
          subService_id: variant.subService_id.id,
          service_id: variant.service_id.id,
        };
      });
    },

    async fetchServices() {
      try {
        const response = await axios.get(`/admin/admin/service/all`);
        this.services = response.data.data;
        this.meta = response.data.meta;
        console.log("services");
        console.log(this.services);
      } catch (error) {
        console.error("Error fetching service:", error);
      }
    },

    async fetchSpecialities() {
      // Corrected method name
      try {
        const response = await axios.get(`/admin/admin/speciality/all`);
        this.specialities = response.data.data;
        this.meta = response.data.meta;
        console.log("specialities");
        console.log(this.specialities);
      } catch (error) {
        console.error("Error fetching specialities:", error);
      }
    },

    async fetchInfoServices(serviceId) {
      console.log("fetchInfoServices:");

      try {
        const response = await axios.get(
          `/admin/admin/info-service/all?service_id=${serviceId}`
        );
        this.infoServices = response.data.data;
        this.meta = response.data.meta;
        // console.log("infoServices");
        // console.log(this.infoServices);
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchSubServices(serviceId) {
      console.log("fetchInfoServices:");

      try {
        const response = await axios.get(
          `/admin/admin/sub-service/all?service_id=${serviceId}`
        );
        this.subServices = response.data.data;
        this.meta = response.data.meta;
        console.log("SubServices");
        console.log(this.SubServices);
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    // Updated to call fetchInfoServices with service_id
    handleServiceChange(selectedService) {
      console.log("Selected service:", selectedService);
      if (selectedService) {
        this.fetchInfoServices(selectedService); // Fetch info services based on selected service ID
        this.fetchSubServices(selectedService); // Fetch info services based on selected service ID
      } else {
        this.infoServices = []; // Clear info services if no service is selected
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
