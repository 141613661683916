import axios from "axios";

// axios.defaults.baseURL = "https://coma-backend.developnetwork.net";
axios.defaults.baseURL = "https://allodoctor-backend.developnetwork.net";

// axios.defaults.baseURL = "http://192.168.1.172:8000";
// axios.defaults.baseURL = "http://127.0.0.1:8000";

const token = localStorage.getItem("token");
console.log("token: ", token);

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export const setAuthorizationHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default axios;

