<template>
  <div class="home">
    <!-- <NavBar /> -->
    <h1 v-if="access_token">hello</h1>
    <h3 v-if="!access_token">not logged in</h3>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  props: ["orders"],
  date() {
    return {
      access_token: null,
    };
  },
  async beforeCreate() {
    this.access_token = localStorage.getItem("access_token");
  },
};
</script>
