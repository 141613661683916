<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Branch</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-branches' }"
              >Back</router-link
            >
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form
              class="text-start mx-auto"
              @submit.prevent="createBranch"
              enctype="multipart/form-data"
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter English Name"
                      v-model="name_en"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="brancheName">Arabic Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Arabic Name"
                      v-model="name_ar"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="brancheName">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Address"
                      v-model="address"
                    />
                  </div>
                </div>

                <!-- GoogleMap component with lat and long props -->
                <!-- <google-map :lat="lat" :long="long" /> -->

                <GoogleMap
                  :coords="{ lat: lat, lng: long }"
                  @placeChanged="handlePlaceChanged"
                />
                <div class="form-group">
                  <label>Latitude</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Latitude"
                    v-model="lat"
                  />
                </div>

                <div class="form-group">
                  <label>Longitude</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Longitude"
                    v-model="long"
                  />
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import GoogleMap from "../Component/GoogleMap.vue";

export default {
  name: "CreateBranch",
  components: {
    GoogleMap,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      address: "",
      lat: 30.0444, // Lat and long for input fields
      long: 31.2357, // long and long for input fields
    };
  },

  methods: {
    // handleFileChange(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.image = file; // Update with the selected file
    //   }
    // },
    // async fetchCountries() {
    //   try {
    //     const response = await axios.get("/admin/country/getAll");
    //     this.countries = response.data.data;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async createBranch() {
      try {
        // Prepare form data for upload
        const formData = new FormData();
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("address", this.address);
        formData.append("lat", this.lat);
        formData.append("long", this.long);

        const response = await axios.post(
          "admin/admin/branche/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-branches" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
