<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Branches</h1>
         
            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'create-branche' }"
            >create</router-link
          >
          </div>  
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <h5 class="over-title margin-bottom-15">
              Basic <span class="text-bold">Data Table</span>
            </h5>
            <p>
              DataTables is a plug-in for the jQuery Javascript library. It is a
              highly flexible tool, based upon the foundations of progressive
              enhancement, and will add advanced interaction controls to any
              HTML table.
            </p>
            <table
              class="table table-striped table-bordered table-hover table-full-width"
              id="sample_1"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name Arabic</th>
                  <th>Name English</th>
                  <!-- <th>Image</th> -->
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
        
                <tr v-for="(branche, index) in filteredBranches" :key="index">
                  <th scope="row">{{ branche.id }}</th>
                  <td>{{ branche.name_ar }}</td>
                  <td>{{ branche.name_en }}</td>
                  <!-- <td>
                    <img
                      :src="`${service.image}`"
                      width="50"
                      height="50"
                      class="img-thumbnail"
                    />
                  </td> -->
                  <!-- <td><img :src="`${assets}service.image`"></td> -->
                  
                  <td>
                    <button
                      class="me-2 btn btn-danger"
                      @click="deleteBranche(branche.id)"
                    >
                      Delete
                    </button>
                    <router-link
                      class="btn btn-primary spacing"
                      :to="{ name: 'edit-branche', params: { id: branche.id } }"
                    >
                      Edit
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>


</template>

<script>
// import Paginate from "vuejs-paginate-next";
// import axios from 'axios';
// import Paginate from "vuejs-paginate-next";
import axios from "../../Axios";
export default {
  name: "AllBranches",
  // components: {
  //   Paginate,
  // },
  data() {
    return {
      branches: [],
      meta: {},
      searchTerm: "",
    };
  },

  async created() {
    this.fetchBranches();
  },

  computed: {
    filteredBranches() {
      if (!this.searchTerm) {
        return this.branches;
      }
      const term = this.searchTerm.toLowerCase();
      return this.branches.filter((branche) =>
        branche.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch cities
    async fetchBranches(page = 1) {
      try {
        const response = await axios.get(
          `/admin/admin/branch/all?page=${page}&is_paginate=15`
        );
        this.branches = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching categoris:", error);
      }
    },

    deleteBranche(id) {
      console.log("delete branche id: ", id);
      axios
        .delete(`/admin/admin/branch/delete/${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchBranches();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>
