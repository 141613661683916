<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Doctor</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-doctors' }"
              >Back</router-link
            >
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editDoctor">
              <div class="row mb-2">
                <div class="form-group col-md-4">
                  <label class="text-"> Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Name"
                    v-model="name"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label class="text-">English Title </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter English Title "
                    v-model="title_en"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label class="text-">Arabic Title</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Arabic Title"
                    v-model="title_ar"
                  />
                </div>

                <div class="form-group col-md-3">
                  <label class="text-">price</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter price"
                    v-model="price"
                  />
                </div>

                <div class="form-group col-md-3">
                  <label class="text-">Price After Discount</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Price After Discount"
                    v-model="price_after_discount"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label class="text-">Experience</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Experience"
                    v-model="experience"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label class="text-">English Description</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="description_en"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <label class="text-">Arabic Description</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="description_ar"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <h5 class="image-label">Images</h5>
                  <input
                    id="images"
                    type="file"
                    class="form-control-file"
                    @change="handleFileUpload"
                    multiple
                    accept="image/*"
                  />

                  <transition-group
                    name="image-list"
                    class="image-previews mt-2"
                  >
                    <img
                      v-for="(image, index) in imagePreviews"
                      :key="image"
                      :src="image"
                      class="img-thumbnail"
                      :alt="'Image ' + (index + 1)"
                      @click="removeImage(index)"
                    />
                  </transition-group>
                </div>

                <div class="form-group col-md-12">
                  <label for="main_image" class="form-label"> main Image</label>
                  <input
                    id="images"
                    type="file"
                    class="form-control-file"
                    @change="handleFileUploadMainImage"
                    multiple
                    accept="image/*"
                  />

                  <transition-group
                    name="image-list"
                    class="image-previews mt-2"
                  >
                    <img
                      v-for="(main_image, index) in main_imagePreviews"
                      :key="main_image"
                      :src="main_image"
                      class="img-thumbnail"
                      :alt="'main_image ' + (index + 1)"
                      @click="removeMainImage(index)"
                    />
                  </transition-group>
                </div>

                <div class="form-group">
                  <button
                    class="btn btn-success add-variant mb-3"
                    @click="addVariant"
                    type="button"
                  >
                    Add Variant
                  </button>
                  <div class="row">
                    <div
                      class="col-md-6"
                      v-for="(variant, index) in variants"
                      :key="index"
                    >
                      <div class="card mb-3">
                        <div class="card-body">
                          <h5 class="card-title">Variant {{ index + 1 }}</h5>

                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">service</label>
                            <multiselect
                              v-model="variant.service_id"
                              :options="services"
                              :searchable="true"
                              label="name"
                              track-by="id"
                              placeholder="Select a service"
                              :multiple="false"
                              @select="
                                handleServiceChange(variant.service_id.id)
                              "
                            ></multiselect>
                          </div>

                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Sub service</label>
                            <multiselect
                              v-model="variant.subService_id"
                              :options="subServices"
                              :searchable="true"
                              label="name"
                              track-by="name"
                              placeholder="Select a Sub service"
                              :multiple="false"
                            ></multiselect>
                          </div>

                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Specialty</label>
                            <multiselect
                              v-model="variant.specialty_id"
                              :options="specialities"
                              :searchable="true"
                              label="name"
                              track-by="name"
                              placeholder="Select a Specialty"
                              :multiple="false"
                            ></multiselect>
                          </div>

                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Infomation Service</label>
                            <multiselect
                              v-model="variant.info_service_id"
                              :options="infoServices"
                              :searchable="true"
                              label="name"
                              track-by="id"
                              placeholder="Select a Infomation Service"
                              :multiple="false"
                            ></multiselect>
                          </div>

                          <button
                            class="btn btn-danger mt-3"
                            @click="removeVariant(index)"
                          >
                            Remove Variant
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
export default {
  name: "EditDoctor",
  data() {
    return {
      name_en: "",
      name_ar: "",
      country_id: null,
      countries: [],
    };
  },
  created() {
    this.fetchDoctor();
    this.fetchCountry();
  },

  methods: {
    async fetchCountry() {
      try {
        const response = await axios.get("/admin/admin/country/all");
        this.countries = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchDoctor() {
      try {
        const response = await axios.get(
          "/admin/admin/doctor/get/" + this.$route.params.id
        );
        console.log(response.data.data.name_en);
        this.name_en = response.data.data.name_en;
        this.name_ar = response.data.data.name_ar;
        this.country_id = response.data.data.country_id;
      } catch (error) {
        console.error(error);
      }
    },
    async editDoctor() {
      try {
        const response = await axios.post(
          "/admin/admin/doctor/update/" + this.$route.params.id,
          {
            name_en: this.name_en,
            name_ar: this.name_ar,
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-doctors" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
