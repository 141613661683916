<template>
  <div>
    <div v-if="errorMessage" :class="{ error: true, 'fade-out': fadeOut }">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: ["errorMessage"],
  data() {
    return {
      fadeOut: false, // Flag to control fade-out animation
    };
  },
  mounted() {
    // Automatically fade out the error message after 3 seconds
    setTimeout(() => {
      this.fadeOut = true;
    }, 2000);
  },
};
</script>

<style scoped>
.error {
  background-color: #640101;
  color: white;
  width: 30%;
  position: absolute;
  top: 20px; /* Adjust the top position as needed */
  right: 20px; /* Adjust the right position as needed */
  padding: 0.5rem; /* Adjust padding for minimal height while maintaining readability */
  border-radius: 5px; /* Optional: Add border radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
  line-height: 1; /* Set line height to 1 to make it as small as possible */
  animation: fade-in 2s forwards; /* Apply fade animation */
}

.fade-out {
  animation: fade-out 2s forwards; /* Apply fade-out animation */
}

@keyframes fade-out {
  0% {
    opacity: 1;
  } /* Start with opacity 1 */
  100% {
    opacity: 0;
  } /* End with opacity 0 */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  } /* Start with opacity 0 */
  100% {
    opacity: 1;
  } /* End with opacity 1 */
}
</style>
