<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit subService</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-subServices' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editsubService">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter English Name"
                      v-model="name_en"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="subServiceName">Arabic Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Arabic Name"
                      v-model="name_ar"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="subServiceName">English Description</label>
                    <textarea
                      rows="3"
                      class="form-control"
                      placeholder="Enter English Description"
                      v-model="description_en"
                    ></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="subServiceName">Arabic Description</label>
                    <textarea
                      rows="3"
                      class="form-control"
                      placeholder="Enter Arabic Description"
                      v-model="description_ar"
                    ></textarea>
                  </div>
                </div>

                <div class="form-group">
                  <label>Image Upload</label>
                  <div class="" data-provides="fileinput">
                    <div class="fileinput-preview fileinput-exists thumbnail">
                      <img ref="imagePreview" :src="imagePreviewUrl" alt="Image Preview">
                    </div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file">
                        <span class="fileinput-new"><i class="fa fa-picture"></i> Select image</span>
                        <span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="image" @change="handleFileChange">
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" @click.prevent="removeImage">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Select Service</label>
                  <select
                    class="form-control"
                    v-model="service_id"
                  >
                    <option
                      v-for="service in services"
                      :key="service.id"
                      :value="service.id"
                    >
                      {{ service.name_en }}
                    </option>
                  </select>

         
                </div>

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "EditsubService",
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      image: null,
      existingImage: "", // URL of the existing image
      removeImageFlag: false, // Flag to indicate if the image should be removed
      services: [],
      service_id: null, // This will hold the selected service id


    };
  },
  computed: {
    imagePreviewUrl() {
      return this.image ? URL.createObjectURL(this.image) : this.existingImage;
    },
  },
  created() {
    this.fetchsubService();
    this.fetchService();
  },
  methods: {
  
    async fetchService() {
      try {
        const response = await axios.get(
          "/admin/admin/service/all"
        );
        console.log('service',response.data.data );
        
        this.services = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchsubService() {
      try {
        const response = await axios.get(
          "/admin/admin/sub-service/get?id=" + this.$route.params.id
        );
        // const response = await axios.get(`admin/admin/subService/get/${this.$route.params.id}`);
        this.name_en = response.data.data.name_en;
        this.name_ar = response.data.data.name_ar;
        this.name = response.data.data.name;
        this.description_en = response.data.data.description_en;
        this.description_ar = response.data.data.description_ar;
        this.description = response.data.data.description;
        this.service_id = response.data.data.service.id;

        this.existingImage = response.data.data.image; // Assuming the response includes an image URL

        // Set up preview for existing image
        if (this.existingImage) {
          this.$nextTick(() => {
            const imgElement = this.$refs.imagePreview;
            imgElement.src = this.existingImage;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.imagePreview.src = e.target.result; // Preview the selected image
        };
        reader.readAsDataURL(file);
      }
    },
    async removeImage() {
      console.log('this.$refs.imagePreview.src',this.$refs.imagePreview.src);

      // console.log('this.image',this.image);
      // console.log('this.removeImageFlag',this.removeImageFlag);
      // const url = new URL(this.existingImage);
         // Send delete request to server
         await axios.post(`admin/admin/sub-subService/delete-image`, {
         image: this.$refs.imagePreview.src ,id:this.$route.params.id, // Send filename in request body
      });
      this.image = null;
      this.removeImageFlag = true;
      this.$refs.imagePreview.src = ""; // Clear the preview image
      // const imageName = url.pathname.split('/').pop(); // Extract filename with extension

      
    },
    async editsubService() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("description_en", this.description_en);
        formData.append("description_ar", this.description_ar);
        if (this.image) {
          formData.append("image", this.image); // Append the selected file
        }
        if (this.removeImageFlag) {
          formData.append("remove_image", true); // Flag to indicate removal
        }
        const response = await axios.post(
          "admin/admin/sub-subService/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-subServices" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white; /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  width: 100%;
}

form > .form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>

